import axios from 'axios';
import configData from './config.js'
// request 拦截器
axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response 拦截器
axios.interceptors.response.use(
  (response) => {
    let data = response.data;

    return data;
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '请求错误';
          break;
        case 401:
          err.message = '未授权，请登录';
          break;
        case 403:
          err.message = '拒绝访问';
          break;
        case 404:
          err.message = `请求地址出错: ${err.response.config.url}`;
          break;
        case 408:
          err.message = '请求超时';
          break;
        case 500:
          err.message = '服务器内部错误';
          break;
        case 501:
          err.message = '服务未实现';
          break;
        case 502:
          err.message = '网关错误';
          break;
        case 503:
          err.message = '服务不可用';
          break;
        case 504:
          err.message = '网关超时';
          break;
        case 505:
          err.message = 'HTTP版本不受支持';
          break;
        default:
      }
    }

    return Promise.reject(err); // 返回接口返回的错误信息
  }
);

export default (options) => {
  const config = {
    baseURL:configData.base,
    // 请求方式
    method: options.method,
    // 请求路径
    url: options.url,
    // 请求头信息
    headers: options.headers || {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('token'),
      'x-app-secret': 'c2737c56837d1032d413055483330196',
    },
    // 参数
    data: options.data || {},
    params: {
      ...options.params,
    },

    // 设置超时时间
    timeout: 60 * 1000,
    // 返回数据类型
    responseType: options.responseType || 'json',
  };

  if (options.pathParams) {
    config.url = config.url.replace('{id}', options.pathParams);
  }

  // const globalUser = store.state.globalUser;
  // if (globalUser) {
  //   config.headers.kunyunToken = globalUser.kunyunToken;
  // }

  return axios.request(config);
};
